import React, { useContext, useState } from "react";
import styles from "./TopMenu.module.css";
import MenuIcon from "@mui/icons-material/Menu";
import SchoolIcon from "@mui/icons-material/School";
import PreviewIcon from "@mui/icons-material/Preview";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import PasswordIcon from "@mui/icons-material/Password";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import GridViewIcon from "@mui/icons-material/GridView";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import FeedbackIcon from "@mui/icons-material/Feedback";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LogoutIcon from "@mui/icons-material/Logout";
import authService from "../../services/authService";
import { useHistory } from "react-router";
import {
  ListItemText,
  Divider,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  SwipeableDrawer,
  useMediaQuery,
  Link,
  ListItemIcon,
} from "@mui/material";
import { setSearchText } from "../PatientListPage/PatientListPage";
import theme from "../theme";
import FeedbackDialog from "../Feedback/Feedback";
import SettingsIcon from "@mui/icons-material/Settings";
import * as Constants from "../../constants";
import { CLINCAL_TRIAL_USER_TYPE } from "../../constants";
import AppContext from "../../context/AppContext";
import { useApolloClient, useMutation } from "@apollo/client";
import SignOutMutation from "./SignOutMutation";
import { SignOut } from "./types/SignOut";
import NotificationInfoMenu from "../NotificationInfoMenu/NotificationInfoMenu";
import { isTenantVet } from "../../services/tenantService";

export type TopmenuProps = {
  display?: string;
  ml?: number;
  style?: React.CSSProperties;
};

const TopMenu: React.FC<TopmenuProps> = (props) => {
  const history = useHistory();
  const isDesktop = useContext(AppContext).isDesktop;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [useBiometrics, setUseBiometrics] = useState(false);
  const client = useApolloClient();

  const mobility = authService.isMobility();
  const checkMobility = mobility;

  const [state, setState] = React.useState({
    right: false,
  });

  const [signOut] = useMutation<SignOut>(SignOutMutation, {
    ignoreResults: true,
    onCompleted: async () => {
      try {
        authService.clearAll();
        await client.clearStore();
        setSearchText("");
        localStorage.removeItem("searchtext");
        if (checkMobility) {
          history.push("/login?logout");
        } else {
          history.push("/login");
        }
      } catch (err) {
        if (checkMobility) {
          history.push("/login?logout");
        } else {
          history.push("/login");
        }
      }
    },
    onError: async () => {
      if (checkMobility) {
        history.push("/login?logout");
      } else {
        history.push("/login");
      }
    },
  });

  const toggleDrawer = (side: "right", open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const receiveMessage = (event: any): void => {
    if (event.data && event.data === "biometricsSupported") {
      setUseBiometrics(true);
    }
  };

  window.addEventListener("message", receiveMessage, false);

  window.addEventListener(
    "beforeunload",
    function () {
      if (window.opener === null || window.opener === undefined) {
        setSearchText("");
        localStorage.removeItem("searchtext");
      }
    },
    false,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    if (authService.isCognito()) {
      authService.clearAll();
      const signoutUrl = process.env.REACT_APP_LOGOUT_URL;
      if (signoutUrl) {
        window.location.replace(`${signoutUrl}`);
      }
    } else {
      await signOut({});
    }
  };

  const handleChangePasswd = () => {
    history.push("/change-password");
  };

  const handleChangeEmail = () => {
    history.push("/account-settings");
  };

  const handleNotificationChange = () => {
    history.push("/notification-settings");
  };

  const handleClickFeedback = () => {
    setOpenFeedbackDialog(true);
  };

  const handleFeedbackClose = () => {
    setOpenFeedbackDialog(false);
  };

  const handleHospitalAdmin = () => {
    history.push("/groupadmin");
  };

  const handleBiometrics = () => {
    const randomString = Math.random().toString(36).substring(7);
    history.push(`?biometrics=${randomString}`);
  };

  const handlePreviousTestRequests = () => {
    history.push("/previous-test-requests");
  };

  const handleChangeReportView = (reportView: string) => {
    authService.setReportView(reportView);
    //@todo: To make a smoother action, we should use the props to send back the view change.
    window.location.reload();
  };

  const menus = [
    {
      name: "Account Settings",
      text: "Account Settings",
      icon: <SettingsIcon />,
      type: "menu",
      url: "/account-settings",
    },
    {
      name: "Notifications Settings",
      text: "Notifications Settings",
      type: "menu",
      icon: <EditNotificationsIcon />,
      url: "/notification-settings",
    },
    {
      name: "Change Password",
      text: "Change Password",
      type: "menu",
      icon: <PasswordIcon />,
      showAt: !authService.isCognito(),
      url: "/change-password",
    },
    { type: "divider" },
    {
      name: "Standard View",
      text: "Standard View",
      type: "menu",
      icon: <GridViewIcon />,
      showAt:
        window.location.href.indexOf("/patient/") > -1 &&
        window.location.href.indexOf("/labnumber/") > -1 &&
        authService.getClinicalTrial() !== CLINCAL_TRIAL_USER_TYPE.Standard &&
        authService.getReportView() === "2",
      onClickEvent: () => handleChangeReportView("1"),
    },
    {
      name: "Combined View",
      text: "Combined View",
      type: "menu",
      icon: <CallMergeIcon />,
      showAt:
        window.location.href.indexOf("/patient/") > -1 &&
        window.location.href.indexOf("/labnumber/") > -1 &&
        authService.getClinicalTrial() !== CLINCAL_TRIAL_USER_TYPE.Standard &&
        authService.getReportView() === "1",
      onClickEvent: () => handleChangeReportView("2"),
    },
    {
      type: "divider",
      showAt:
        window.location.href.indexOf("/patient/") > -1 &&
        window.location.href.indexOf("/labnumber/") > -1 &&
        authService.getClinicalTrial() !== CLINCAL_TRIAL_USER_TYPE.Standard,
    },
    {
      name: "Previous eOrders",
      text: "Previous eOrders",
      icon: <PreviewIcon />,
      type: "menu",
      url: "/previous-test-requests",
    },
    {
      name: "CPD Portal",
      text: "CPD Portal",
      icon: <SchoolIcon />,
      type: "menu",
      showAt:
        !isTenantVet() &&
        (authService.getCPDEnrolled().length > 0 || authService.validProviders()) &&
        window.location.href.indexOf("/cpd-program") <= 0,
      url: "/cpd-programs",
    },
    {
      name: "Back to eResults",
      text: "Back to eResults",
      icon: <SchoolIcon />,
      type: "menu",
      showAt:
        (authService.getCPDEnrolled().length > 0 || authService.validProviders()) &&
        window.location.href.indexOf("/cpd-program") > -1,
      url: "/patients",
    },
    { type: "divider" },
    {
      name: "Give Us Feedback",
      text: "Give Us Feedback",
      type: "menu",
      icon: <FeedbackIcon />,
      onClickEvent: () => handleClickFeedback(),
    },
    {
      name: "Group Admin",
      text: "Group Admin",
      icon: <SupervisorAccountIcon />,
      showAt: authService.isHospitalAdmin(),
      classes: styles.MenuItem3,
      type: "menu",
      url: "/groupadmin",
    },

    {
      name: "User Guide",
      text: "User Guide",
      type: "link",
      icon: <LiveHelpIcon />,
      url: Constants.getUserGuide(),
    },
    { type: "divider" },

    {
      name: "Enable Biometrics",
      text: "Enable Biometrics",
      type: "menu",
      icon: <FingerprintIcon />,
      showAt: useBiometrics,
      onClickEvent: () => handleBiometrics(),
    },
    {
      name: "Sign out",
      text: "Sign out",
      type: "menu",
      icon: <LogoutIcon />,
      onClickEvent: () => handleLogout(),
    },
  ];

  const mobileMenus = [
    {
      name: "Account Settings",
      text: "Account Settings",
      onClickEvent: () => handleChangeEmail(),
      icon: <SettingsIcon />,
      type: "menu",
    },
    {
      name: "Notification Settings",
      text: "Notification Settings",
      icon: <EditNotificationsIcon />,
      onClickEvent: () => handleNotificationChange(),
      type: "menu",
    },
    {
      name: "Change Password",
      text: "Change Password",
      icon: <PasswordIcon />,
      showAt: !authService.getSharedAccount() && !authService.isCognito(),
      onClickEvent: () => handleChangePasswd(),
      type: "menu",
    },
    { type: "divider" },
    {
      name: "Previous eOrders",
      text: "Previous eOrders",
      icon: <PreviewIcon />,
      onClickEvent: () => handlePreviousTestRequests(),
      type: "menu",
    },
    {
      name: "CPD Portal",
      text: "CPD Portal",
      icon: <SchoolIcon />,
      type: "menu",
      showAt:
        !isTenantVet() &&
        (authService.getCPDEnrolled().length > 0 || authService.validProviders()) &&
        window.location.href.indexOf("/cpd-program") <= 0,
      url: "/cpd-programs",
    },
    { type: "divider" },
    {
      name: "Back to eResults",
      text: "Back to eResults",
      icon: <SchoolIcon />,
      type: "menu",
      showAt:
        (authService.getCPDEnrolled().length > 0 || authService.validProviders()) &&
        window.location.href.indexOf("/cpd-program") > -1,
      url: "/patients",
    },
    {
      name: "Give Us Feedback",
      text: "Give Us Feedback",
      icon: <FeedbackIcon />,
      onClickEvent: () => handleClickFeedback(),
      type: "menu",
    },
    {
      name: "Group Admin",
      text: "Group Admin",
      icon: <SupervisorAccountIcon />,
      showAt: authService.isHospitalAdmin(),
      onClickEvent: () => handleHospitalAdmin(),
      type: "menu",
    },
    {
      name: "User Guide",
      text: "User Guide",
      icon: <LiveHelpIcon />,
      type: "link",
      url: Constants.getUserGuide(),
    },
    { type: "divider" },
    {
      name: "Enable Biometrics",
      text: "Enable Biometrics",
      icon: <FingerprintIcon />,
      showAt: useBiometrics,
      onClickEvent: () => handleBiometrics(),
      type: "menu",
    },

    {
      name: "Sign out",
      text: "Sign out",
      icon: <LogoutIcon />,
      type: "menu",
      onClickEvent: () => handleLogout(),
    },
  ];
  const MenuElement = (inputMenu: any) => {
    return (
      <div>
        {inputMenu.inputMenu.map((menuItem: any, index: any) => {
          if (menuItem.type === "menu" && menuItem.showAt != false) {
            return (
              <MenuItem key={index}>
                {menuItem.icon && <ListItemIcon> {menuItem.icon}</ListItemIcon>}
                <Button
                  onClick={() => {
                    if (menuItem.onClickEvent) {
                      menuItem.onClickEvent();
                    } else if (menuItem.url) {
                      history.push(menuItem.url);
                    }
                  }}
                  className={menuItem.classes ? menuItem.classes : styles.MenuItem}
                  color="secondary"
                >
                  {menuItem.name}
                </Button>
              </MenuItem>
            );
          } else if (menuItem.type === "divider" && menuItem.showAt != false) {
            return <Divider key={index} className={styles.Divider} />;
          } else if (menuItem.type === "header") {
            return (
              <MenuItem key={index}>
                {menuItem.icon && <ListItemIcon> {menuItem.icon}</ListItemIcon>}
                <ListItemText>{menuItem.text}</ListItemText>
              </MenuItem>
            );
          } else if (menuItem.type === "link" && menuItem.showAt != false) {
            return (
              <MenuItem key={index}>
                {menuItem.icon && <ListItemIcon> {menuItem.icon}</ListItemIcon>}
                <Link href={Constants.getUserGuide()} target="blank" className={styles.MenuItemLinkDesktop}>
                  User Guide
                </Link>
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  return (
    <Box display={props.display} ml={props.ml}>
      <FeedbackDialog open={openFeedbackDialog} fullscreen={fullscreen} onClose={handleFeedbackClose} />
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={styles.sideMenu}
          onClick={handleClick}
          endIcon={<MenuIcon />}
          style={props.style}
        >
          {authService.getUsername().toUpperCase()}
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MenuElement inputMenu={menus} />
        </Menu>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        {!isDesktop ? <NotificationInfoMenu /> : ""}
        <IconButton aria-label="delete" onClick={toggleDrawer("right", true)} className={styles.sideMenu}>
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          <MenuElement inputMenu={mobileMenus} />
        </SwipeableDrawer>
      </Box>
    </Box>
  );
};

export default TopMenu;
